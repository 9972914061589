import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, MeasureModification, MeasureState} from "../../../domain/types";
import {gs} from "../../../theme";
import {MeasureForm} from "./MeasureForm";

interface AssetAddMeasureProps {}

export function AssetAddMeasure(props: AssetAddMeasureProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [risks, setRisks] = useState<IAssetRisk[]>([]);
    const [measure, setMeasure] = useState<MeasureModification>({
        priority: 4,
        state: MeasureState.Identified,
        titles: [],
        descriptions: [],
        risk: {
            probability: 1,
            severity: 1
        }
    });
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risks(tenantUuid, routeParams.assetUuid).then(setRisks);
        }
    }, [api, tenantUuid, routeParams]);

    const canCreate = useMemo<boolean>(
        () => Boolean(measure.titles.length > 0 && measure.descriptions.length > 0 && measure.assetRisk),
        [measure]
    );

    const handleAddMeasure = useCallback(() => {
        if (asset && measure.assetRisk && canCreate && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .addMeasure(tenantUuid, asset.uuid, measure.assetRisk.uuid, measure)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [canCreate, measure, api, tenantUuid, asset, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("measures.addMeasures")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: t("measures.addMeasure"),
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}/measures/add` : "/assets"
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            {measure && (
                                <Grid item xs={12}>
                                    <MeasureForm measure={measure} onChange={setMeasure} risks={risks} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleAddMeasure}
                                    color="primary"
                                    variant="contained"
                                    disabled={!canCreate || submitting}
                                >
                                    {t("measures.addMeasure")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
