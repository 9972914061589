import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {TaskState} from "../../domain/types";

interface TaskStateDisplayProps {
    state: TaskState;
}

export function TaskStateDisplay(props: TaskStateDisplayProps) {
    const {state} = props;
    const {t} = useLanguageContext();

    let backgroundColor = undefined;
    let color = undefined;
    let label = t("measures.labels.Unknown");
    switch (state) {
        case TaskState.Created:
            backgroundColor = red[700];
            color = "white";
            label = t("tasks.states.Created");
            break;
        case TaskState.InProgress:
            backgroundColor = orange[300];
            label = t("tasks.states.InProgress");
            break;
        case TaskState.Completed:
            backgroundColor = green[700];
            color = "white";
            label = t("tasks.states.Completed");
            break;
    }

    return <Chip label={label} sx={{backgroundColor, color}} />;
}
