import React, {useCallback, useMemo, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {TaskState} from "../../domain/types";
import {IconButton} from "@mui/material";
import {CheckBoxOutlineBlank, PlayArrow, RestartAlt} from "@mui/icons-material";

interface TaskStateQuickChangeButtonProps {
    taskState: TaskState;
    onChange: (nextState: TaskState) => Promise<void>;
}

export function TaskStateQuickChangeButton(props: TaskStateQuickChangeButtonProps) {
    const {taskState, onChange} = props;
    const {t} = useLanguageContext();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const nextState = useMemo<TaskState>(() => {
        switch (taskState) {
            case TaskState.Created: return TaskState.InProgress;
            case TaskState.InProgress: return TaskState.Completed;
            case TaskState.Completed: return TaskState.InProgress;
        }
    }, [taskState]);

    const handleNextState = useCallback(async () => {
        setSubmitting(true);
        await onChange(nextState);
        setSubmitting(false);
    }, [nextState, onChange]);

    return (
        <IconButton onClick={handleNextState} size="small" disabled={submitting}>
            {taskState == TaskState.Created &&
                <PlayArrow />
            }
            {taskState == TaskState.InProgress &&
                <CheckBoxOutlineBlank />
            }
            {taskState == TaskState.Completed &&
                <RestartAlt />
            }
        </IconButton>
    );
}