import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Button, Container, Grid} from "@mui/material";
import {gs} from "../../../theme";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, ITask, TaskState} from "../../../domain/types";
import {TasksProvider} from "../../../domain/TasksProvider";
import {TaskWrapper} from "../../../domain/tasks";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {TaskView} from "../../tasks/TaskView";

interface AssetViewTaskProps {

}

export function AssetViewTask(props: AssetViewTaskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t, language} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [task, setTask] = useState<ITask>();

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            const tasksProvider = new TasksProvider(api);
            tasksProvider.get(tenantUuid, routeParams.taskUuid).then(setTask);
        }
    }, [api, tenantUuid, routeParams]);

    const taskWrapper = useMemo<TaskWrapper | undefined>(() => task ? new TaskWrapper(task) : undefined, [task]);

    const handleStateChange = useCallback((nextState: TaskState) => {
        if (routeParams.assetUuid && task && api.auth) {
            const tasksProvider = new TasksProvider(api);
            return tasksProvider.updateState(tenantUuid, task.uuid, nextState).then((updatedTask) => {
                setTask(updatedTask);
            });
        }
        throw new Error(`React component in an illegal state to handleStateChange`);
    }, [task]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs title={taskWrapper ? taskWrapper.title(language) : "..."} crumbs={[
                        {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                        {
                            label: asset ? asset.name : "...",
                            href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                        },
                        {
                            label: taskWrapper ? taskWrapper.title(language) : "...",
                            href: (asset && task) ? `/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/${task.uuid}` : "/assets"
                        }
                    ]}>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks/${routeParams.taskUuid}/edit`)}
                        >
                            {t("edit")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {task &&
                    <>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <TaskView task={task} onStateChange={handleStateChange} />
                            </BoxedPaper>
                        </Grid>
                    </>
                }
            </Grid>
        </Container>
    );
}