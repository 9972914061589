import React from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IAssetRisk} from "../../../domain/types";
import {Grid} from "@mui/material";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {LabeledData} from "../../../base/data/LabeledData";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";

interface RiskViewProps {
    assetRisk: IAssetRisk;
}

export function RiskView(props: RiskViewProps) {
    const {assetRisk} = props;
    const {t, es} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <LabeledData label={t("name")}>
                    {assetRisk.name}
                </LabeledData>
            </Grid>
            <AGridItemGrow>
                <LabeledData label={t("tools.headerFields.category")}>
                    {es("risks.riskCategories", assetRisk.category)}
                </LabeledData>
            </AGridItemGrow>
            <LabeledItem label={t("assets.headerNames.probability")}>
                <ProbabilityDisplay probability={assetRisk.risk.probability} />
            </LabeledItem>
            <LabeledItem label={t("assets.headerNames.severity")}>
                <SeverityDisplay severity={assetRisk.risk.severity} />
            </LabeledItem>
            <LabeledItem label={t("assets.headerNames.grossRisk")}>
                <TotalRiskDisplay totalRisk={assetRisk.risk.probability * assetRisk.risk.severity} />
            </LabeledItem>

            {assetRisk.descriptions.length > 0 &&
                <Grid item xs={12}>
                    <LabeledData label={t("assets.headerNames.description")}>
                        <MultiLingualTextDisplay texts={assetRisk.descriptions} />
                    </LabeledData>
                </Grid>
            }
        </Grid>
    );
}