import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, IMeasure, TaskModification} from "../../../domain/types";
import {gs} from "../../../theme";
import {TasksProvider} from "../../../domain/TasksProvider";
import {TaskForm} from "./TaskForm";

interface AssetAddTaskProps {}

export function AssetAddTask(props: AssetAddTaskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [risks, setRisks] = useState<IAssetRisk[]>([]);
    const [measures, setMeasures] = useState<IMeasure[]>([]);
    const [task, setTask] = useState<TaskModification>({
        titles: [],
        descriptions: []
    });
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risks(tenantUuid, routeParams.assetUuid).then(setRisks);
            assetsProvider.measures(tenantUuid, routeParams.assetUuid).then(setMeasures);
        }
    }, [api, tenantUuid, routeParams]);

    const canCreate = useMemo<boolean>(
        () => Boolean(task.titles.length > 0 && task.descriptions.length > 0),
        [task]
    );

    const handleAddTask = useCallback(() => {
        if (asset && canCreate && api.auth) {
            setSubmitting(true);
            const tasksProvider = new TasksProvider(api);
            tasksProvider
                .create(tenantUuid, task)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [canCreate, task, api, tenantUuid, asset, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tasks.addTask")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: t("tasks.addTask"),
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/add` : "/assets"
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            {asset && (
                                <Grid item xs={12}>
                                    <TaskForm task={task} onChange={setTask} asset={asset} risks={risks} measures={measures} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleAddTask}
                                    color="primary"
                                    variant="contained"
                                    disabled={!canCreate || submitting}
                                >
                                    {t("tasks.addTask")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
