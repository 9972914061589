import {Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {AuthProvider} from "../../domain/AuthProvider";
import {AuthenticatedToken} from "../../domain/auth";
import {Api} from "../../libs/Api";
import {gs} from "../../theme";

interface AuthProcessTokenProps {}

enum ProcessTokenState {
    Processing = "Processing",
    Success = "Success",
    Error = "Error"
}

export function AuthProcessToken(props: AuthProcessTokenProps) {
    const {provideToken} = useAuthContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [processTokenState, setProcessTokenState] = useState<ProcessTokenState>(ProcessTokenState.Processing);

    useEffect(() => {
        const search = document.location.search;
        const params = new URLSearchParams(search);
        const code = params.get("code");
        if (!code) {
            setProcessTokenState(ProcessTokenState.Error);
            return;
        }
        const authProvider = new AuthProvider(new Api("/auth/login"));
        authProvider
            .processToken(code)
            .then((response) => {
                provideToken(new AuthenticatedToken(response));
                setProcessTokenState(ProcessTokenState.Success);
                navigate("/current-user/tenant-select");
            })
            .catch(() => {
                setProcessTokenState(ProcessTokenState.Error);
            });
    }, [provideToken, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("processingToken.title")}
                        crumbs={[{label: t("login.singular"), href: "/auth/login"}]}
                    />
                </Grid>
                <Grid item xs={12}>
                    {processTokenState == ProcessTokenState.Processing && (
                        <Typography variant="body1">{t("processingToken.processing")}</Typography>
                    )}
                    {processTokenState == ProcessTokenState.Success && (
                        <Typography variant="body1">{t("processingToken.success")}</Typography>
                    )}
                    {processTokenState == ProcessTokenState.Error && (
                        <Typography variant="body1">{t("processingToken.error")}</Typography>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
