import * as React from "react";
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Api} from "../../libs/Api";
import {AuthenticatedToken} from "../../domain/auth";
import {AuthProvider} from "../../domain/AuthProvider";

export interface AuthContextData {
    auth: AuthenticatedToken;
    provideToken: (token: AuthenticatedToken) => void;
    logout: () => void;
    refresh: () => Promise<void>;
    api: Api;
}

const emptyContext: AuthContextData = {
    auth: AuthenticatedToken.nonAuthenticated(),
    provideToken: (token: AuthenticatedToken) => {},
    logout: () => {},
    refresh: async () => {},
    api: new Api("/")
};

export const AuthContext = createContext<AuthContextData>(emptyContext);

let intervalRef: NodeJS.Timeout | null = null;

interface AuthContextProviderProps extends PropsWithChildren<{
    notAuthenticatedUrl: string;
}> {}

export function AuthContextProvider({notAuthenticatedUrl, children}: AuthContextProviderProps) {
    const [auth, setAuth] = useState<AuthenticatedToken>(AuthenticatedToken.fromSessionStorage() || emptyContext.auth);

    useEffect(() => {
        const token = AuthenticatedToken.fromSessionStorage();
        if(token && token.isLoggedIn) {
            setAuth(token);
        }
    }, []);


    const provideToken = useCallback((newAuth: AuthenticatedToken) => {
        if(!newAuth.isLoggedIn) {
            throw new Error("You cannot provide an invalid token");
        }
        setAuth(newAuth);
        newAuth.toSessionStorage();
    }, []);

    const logout = useCallback(() => {
        auth.clearSessionStorage();
        setAuth(emptyContext.auth);
    }, [auth]);

    const refresh = useCallback(async () => {
        const authProvider = new AuthProvider(new Api("/auth/login"));
        authProvider
            .refreshToken(auth.refreshToken)
            .then((response) => {
                provideToken(new AuthenticatedToken(response));
            })
            .catch(() => {
                logout();
            });
    }, [auth, logout, provideToken]);

    useEffect(() => {
        if (auth.isLoggedIn) {
            intervalRef = setInterval(async () => {
                if (auth.requiresRefresh()) {
                    console.log(`Auth token has expiry date of ${auth.expiresAt.toISOString()}, is setup to refresh after ${auth.refreshAfter.toISOString()} refreshing token`);
                    await refresh();
                }
            }, 1000 * 60 * 5);
        }
        return () => {
            if (intervalRef) {
                clearInterval(intervalRef);
                intervalRef = null;
            }
        };
    }, [auth, refresh]);

    const api = useMemo<Api>(() => new Api(notAuthenticatedUrl, auth), [auth, notAuthenticatedUrl]);

    const currentContext = useMemo<AuthContextData>(() => ({
        auth, provideToken, logout, refresh, api
    }), [auth, provideToken, logout, api]);

    return (
        <AuthContext.Provider value={currentContext}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext(): AuthContextData {
    return useContext<AuthContextData>(AuthContext);
}