import {MenuItem, TextField} from "@mui/material";
import React, {useCallback, useEffect, useMemo} from "react";
import {IAssetRisk} from "../../domain/types";

interface RiskSelectInputProps {
    label: string;
    options: IAssetRisk[];
    value?: IAssetRisk;
    onChange: (value: IAssetRisk) => void;
    disabled?: boolean;
}

export function RiskSelectInput(props: RiskSelectInputProps) {
    const {label, options, value, onChange, disabled} = props;

    const selectedValue = useMemo(() => {
        let uuid: string;
        if (value) {
            uuid = value.uuid;
        }
        return options.find((o) => o.uuid === uuid) || "";
    }, [options, value]);

    useEffect(() => {
        if (value) {
            const selected = options.find((o) => o.uuid === value.uuid);
            if (selected) {
                onChange(selected);
            }
        }
    }, [value, options, onChange]);

    const handleChange = useCallback(
        (value: string) => {
            const selected = options.find((o) => o.uuid === value);
            if (selected) {
                onChange(selected);
            }
        },
        [options, onChange]
    );

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            value={selectedValue ? selectedValue.uuid : ""}
            disabled={disabled}
            onChange={(event) => handleChange(event.target.value)}
        >
            {options.map((v) => (
                <MenuItem key={v.uuid} value={v.uuid}>
                    {v.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
