import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetRiskModification, IAsset, IAssetRisk} from "../../../domain/types";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {gs} from "../../../theme";
import {Button, Container, Grid} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {RiskView} from "./RiskView";
import {MetaDataDisplay} from "../../../base/data/MetaDataDisplay";

interface AssetViewRiskProps {

}

export function AssetViewRisk(props: AssetViewRiskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [assetRisk, setAssetRisk] = useState<IAssetRisk>();

    useEffect(() => {
        if (routeParams.assetUuid && routeParams.riskUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risk(tenantUuid, routeParams.assetUuid, routeParams.riskUuid).then(setAssetRisk);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={assetRisk ? assetRisk.name : "..."}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`
                            },
                            {
                                label: assetRisk ? assetRisk.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}`
                            }
                        ]}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}/edit`)}
                        >
                            {t("edit")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {assetRisk && (
                    <>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <RiskView assetRisk={assetRisk} />
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <MetaDataDisplay metaData={assetRisk.metaData} />
                            </BoxedPaper>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );

}