import {Divider, Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {MeasureStateInput} from "../../../base/inputs/MeasureStateInput";
import {PriorityInput} from "../../../base/inputs/PriorityInput";
import {ProbabilityInput} from "../../../base/inputs/ProbabilityInput";
import {RiskSelectInput} from "../../../base/inputs/RiskSelectInput";
import {SeverityInput} from "../../../base/inputs/SeverityInput";
import {TextInput} from "../../../base/inputs/TextInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IAssetRisk, MeasureModification, MeasureState} from "../../../domain/types";
import {gs} from "../../../theme";

interface MeasureFormProps {
    measure: MeasureModification;
    onChange: (value: MeasureModification) => void;
    risks: IAssetRisk[];
}

export function MeasureForm(props: MeasureFormProps) {
    const {measure, risks, onChange} = props;

    const {t} = useLanguageContext();

    const [assetRisk, setAssetRisk] = useState<IAssetRisk>();
    const [priority, setPriority] = useState<number>(4);
    const [state, setState] = useState<MeasureState>(MeasureState.Identified);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [probability, setProbability] = useState<number>(1);
    const [severity, setSeverity] = useState<number>(1);

    useEffect(() => {
        setPriority(measure.priority);
        setState(measure.state);

        setAssetRisk(measure.assetRisk);

        if (measure.titles.length > 0) {
            setTitle(measure.titles[0].text);
        }
        if (measure.descriptions.length > 0) {
            setDescription(measure.descriptions[0].text);
        }
        setProbability(measure.risk.probability);
        setSeverity(measure.risk.severity);
    }, [measure]);

    const handleTitleChanged = useCallback(
        (title: string) => {
            setTitle(title);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handlePriorityChanged = useCallback(
        (priority: number) => {
            setPriority(priority);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handleStateChanged = useCallback(
        (state: MeasureState) => {
            setState(state);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handleProbabilityChanged = useCallback(
        (probability: number) => {
            setProbability(probability);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handleSeverityChanged = useCallback(
        (severity: number) => {
            setSeverity(severity);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handleDescriptionChanged = useCallback(
        (description: string) => {
            setDescription(description);
            onChange({
                priority,
                state,
                assetRisk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    const handleRiskChanged = useCallback(
        (risk: IAssetRisk) => {
            setAssetRisk(risk);
            onChange({
                priority,
                state,
                assetRisk: risk,
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [priority, state, assetRisk, title, description, probability, severity, onChange]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <Grid item xs={12} sm={6}>
                        {risks && (
                            <RiskSelectInput
                                label={t("risks.singular")}
                                options={risks}
                                value={assetRisk}
                                onChange={handleRiskChanged}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {assetRisk && (
                            <Grid container spacing={gs}>
                                <LabeledItem label={t("probability.singular")}>
                                    <ProbabilityDisplay probability={assetRisk.risk.probability} />
                                </LabeledItem>
                                <LabeledItem label={t("severity.singular")}>
                                    <ProbabilityDisplay probability={assetRisk.risk.severity} />
                                </LabeledItem>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
                <TextInput label={t("measures.formLabels.Title")} value={title} onChange={handleTitleChanged} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <MeasureStateInput label={t("measures.formLabels.State")} value={state} onChange={handleStateChanged} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <PriorityInput
                    label={t("measures.formLabels.Priority")}
                    value={priority}
                    onChange={handlePriorityChanged}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ProbabilityInput
                    label={t("measures.formLabels.Probability")}
                    value={probability}
                    onChange={handleProbabilityChanged}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <SeverityInput
                    label={t("measures.formLabels.Severity")}
                    value={severity}
                    onChange={handleSeverityChanged}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("measures.formLabels.Description")}
                    rows={10}
                    value={description}
                    onChange={handleDescriptionChanged}
                />
            </Grid>
        </Grid>
    );
}
