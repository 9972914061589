import * as React from "react";
import {IAssetRisk, IKiTool} from "../../../domain/types";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {TypeSpecimen} from "@mui/icons-material";
import {LabeledData} from "../../../base/data/LabeledData";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {useMemo} from "react";

interface StepSummaryProps {
    kiTool?: IKiTool;
    name?: string;
    description?: string;
    questionnaire?: QuestionnaireWrapper;
    risks?: IAssetRisk[];
}

export function StepSummary(props: StepSummaryProps) {
    const {kiTool, name, description, questionnaire, risks} = props;
    const {t, es} = useLanguageContext();

    const sortedRisks = useMemo<IAssetRisk[] | undefined>(() => {
        if (risks) {
            return risks.sort((a, b) => b.risk.probability * b.risk.severity - a.risk.probability * a.risk.severity);
        }
        return undefined;
    }, [risks]);

    return (
        <Grid container spacing={gs}>
            {name && <LabeledItem label={t("name")}>{name}</LabeledItem>}
            {kiTool && name && kiTool.name != name && (
                <LabeledItem label={t("tools.kiTool.singular")}>{kiTool.name}</LabeledItem>
            )}
            {description && (
                <LabeledItem label={t("tools.description")}>{description}</LabeledItem>
            )}
            {sortedRisks &&
                <Grid item xs={12}>
                    <LabeledData label={t("risks.plural")}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Typography variant="body2">{t("risks.wizard.generatedRisks")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    {sortedRisks.map((r) => (
                                        <ListItem key={r.category}>
                                            <ListItemText primary={r.name} secondary={es("risks.riskCategories", r.category)} />
                                            <ListItemSecondaryAction>
                                                <TotalRiskDisplay totalRisk={r.risk.probability * r.risk.severity} />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </LabeledData>
                </Grid>
            }
        </Grid>
    );
}
