import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {AssetRiskModification, IAsset, IAssetRisk, RiskCategory} from "../../../domain/types";
import {gs} from "../../../theme";
import {Button, Grid} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import Container from "@mui/material/Container";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {AssetRiskForm} from "./AssetRiskForm";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {ConfirmDeleteButton} from "../../../base/buttons/ConfirmDeleteButton";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface AssetEditRiskProps {}

export function AssetEditRisk(props: AssetEditRiskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [assetRisk, setAssetRisk] = useState<AssetRiskModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && routeParams.riskUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risk(tenantUuid, routeParams.assetUuid, routeParams.riskUuid).then(setAssetRisk);
        }
    }, [api, tenantUuid, routeParams]);

    const canSave = useMemo<boolean>(
        () => Boolean(assetRisk && assetRisk.name && assetRisk.category && assetRisk.descriptions.length > 0),
        [assetRisk]
    );

    const handleSaveRisk = useCallback(() => {
        if (asset && assetRisk && routeParams.riskUuid && canSave && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .modifyRisk(tenantUuid, asset.uuid, routeParams.riskUuid, assetRisk)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/risks/${routeParams.riskUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, assetRisk, api, tenantUuid, asset, navigate]);

    const handleDeleteRisk = useCallback(() => {
        if (asset && routeParams.riskUuid && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .deleteRisk(tenantUuid, asset.uuid, routeParams.riskUuid)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, asset, api, tenantUuid, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("risks.editRisk")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: assetRisk ? assetRisk.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}`
                            },
                            {
                                label: t("risks.editRisk"),
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}/edit`
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                {assetRisk && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={submitting}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <AssetRiskForm assetRisk={assetRisk} onChange={setAssetRisk} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item>
                                            <Button
                                                onClick={handleSaveRisk}
                                                color="primary"
                                                variant="contained"
                                                disabled={!canSave || submitting}
                                            >
                                                {t("save")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <ConfirmDeleteButton
                                                onConfirm={handleDeleteRisk}
                                                title={t("risks.deleteRisk")}
                                                message={`${t("risks.deleteRisk")} ${assetRisk.name}?`}
                                            >
                                                {t("delete")}
                                            </ConfirmDeleteButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
