import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {
    defaultPage,
    MultilingualTextWrapper,
    Page,
    PageChange,
    Questionnaire
} from "../../domain/types";
import {useEffect, useState} from "react";
import {QuestionnairesProvider} from "../../domain/QuestionnairesProvider";
import {Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {GridColDef} from "@mui/x-data-grid";
import {APagingTable} from "../../base/table/APagingTable";

interface QuestionnairesListProps {
}

export function QuestionnairesList(props: QuestionnairesListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [questionnaires, setQuestionnaires] = useState<Page<Questionnaire>>();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (api.auth && api.auth.isLoggedIn) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.list(tenantUuid, pageChange).then(setQuestionnaires);
        }
    }, [api, tenantUuid]);

    const {t, es, language} = useLanguageContext();
    const columns: GridColDef[] = [
        {field: "name", headerName: t("questionnaires.singular"), flex: 1,
            valueGetter: (params) => new MultilingualTextWrapper(params.row.titles).resolved(language)},
        {field: "type", headerName: t("assets.headerNames.toolType"), flex: 1,
            valueGetter: (params) => params.row.type ? es("tools.toolType", params.row.type) : ""},
        {field: "category", headerName: t("assets.headerNames.category"), flex: 1,
            valueGetter: (params) => params.row.category ? es("tools.headers.category", params.row.category) : ""},
        {field: "visibility", headerName: t("tools.headers.visibility"), flex: 1,
            valueGetter: (params) => params.row.visibility ? es("tools.toolVisibility", params.row.visibility) : ""},
    ];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("questionnaires.plural")}
                        crumbs={[{label: t("questionnaires.plural"), href: `/tenants/${tenantUuid}/questionnaires`}]} />
                </Grid>
                {questionnaires && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={questionnaires}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(questionnaire: Questionnaire) => navigate(`/tenants/${tenantUuid}/questionnaires/${questionnaire.slug}`)}
                            onSelectionChanged={setSelected}
                            getRowId={(row) => row.slug}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
