import {Grid} from "@mui/material";
import * as React from "react";
import {IKiTool} from "../../../domain/types";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {ALink} from "../../../base/layout/ALink";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface KiToolDetails {
    kiTool: IKiTool;
}

export function KiToolDetails(props: KiToolDetails) {
    const {kiTool} = props;
    const {tenantUuid} = useTenantContext();
    const {t, es} = useLanguageContext();

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <LabeledItem label={t("tools.headers.type")}>{es("tools.toolType", kiTool.type)}</LabeledItem>
                    <LabeledItem label={t("tools.headers.category")}>{es("tools.toolCategory", kiTool.category)}</LabeledItem>
                    <LabeledItem label={t("tools.headers.visibility")}>{es("tools.toolVisibility", kiTool.visibility)}</LabeledItem>
                    <LabeledItem label={t("tools.headers.vendor")}>{kiTool.vendor}</LabeledItem>
                    {kiTool.basedOn && (
                        <LabeledItem label={t("tools.headers.basedOn")}>
                            <ALink href={`/tenants/${tenantUuid}/tools/${kiTool.basedOn.uuid}`}>
                                {kiTool.basedOn.name}
                            </ALink>
                        </LabeledItem>
                    )}
                </Grid>
            </Grid>
            <LabeledItem label={t("tools.description")}>
                {kiTool.descriptions.map((d) => d.text).join(", ")}
            </LabeledItem>
        </Grid>
    );
}
