import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface LocaleSelectionProps {
    value: string;
    onChange: (locale: string) => void;
    label?: string;
    filter?: string[];
    languages?: string[];
    fullWidth?: boolean;
}

export function LocaleSelection(props: LocaleSelectionProps) {
    const {value, fullWidth, languages} = props;
    const {es, obj} = useLanguageContext();

    const setLocale = (value: string) => {
        props.onChange(value.substring(0, 2));
    };

    const deriveOptionLabel = (key: string | null) => {
        if (key) {
            return es("languages", key);
        }
        return "";
    };

    let filteredLocales = languages || Object.keys(obj("languages"));
    if (props.filter) {
        const filter = props.filter;
        filteredLocales = Object.keys(obj("languages") as any).filter((l) => filter.indexOf(l) == -1);
    }
    return (
        <Autocomplete
            fullWidth={fullWidth}
            options={filteredLocales}
            getOptionLabel={(key) => deriveOptionLabel(key)}
            style={{width: "100%"}}
            value={value}
            disableClearable
            onChange={(event: any, newValue: string | null) => {
                if (newValue) {
                    setLocale(newValue);
                }
            }}
            renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" fullWidth />}
        />
    );
}
