import {Grid} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {EnumSelectInput} from "../../../base/inputs/EnumSelectInput";
import {TextInput} from "../../../base/inputs/TextInput";
import {KiToolType, ToolCategory, ToolModification, Visibility} from "../../../domain/types";
import {gs} from "../../../theme";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface ToolFormProps {
    tool: ToolModification;
    onChange: (value: ToolModification) => void;
}

export function ToolForm(props: ToolFormProps) {
    const {tool, onChange} = props;

    const [name, setName] = useState<string>(tool.name);
    const [kiToolType, setKiToolType] = useState<KiToolType>(tool.type);
    const [category, setCategory] = useState<ToolCategory>(tool.category);
    const [visibility, setVisibility] = useState<Visibility>(tool.visibility);
    const [description, setDescription] = useState<string>("");
    const [vendor, setVendor] = useState<string>(tool.vendor);

    useEffect(() => {
        setName(tool.name);
        setKiToolType(tool.type);
        setCategory(tool.category);
        setVisibility(tool.visibility);
        if (tool.descriptions.length > 0) {
            setDescription(tool.descriptions[0].text);
        }
        setVendor(tool.vendor);
    }, [tool]);

    const handleNameChanged = useCallback(
        (name: string) => {
            setName(name);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [kiToolType, category, visibility, description, vendor, onChange]
    );

    const handleTypeChanged = useCallback(
        (kiToolType: KiToolType) => {
            setKiToolType(kiToolType);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [name, category, visibility, description, vendor, onChange]
    );

    const handleCategoryChanged = useCallback(
        (category: ToolCategory) => {
            setCategory(category);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [name, kiToolType, visibility, description, vendor, onChange]
    );

    const handleVisibilityChanged = useCallback(
        (visibility: Visibility) => {
            setVisibility(visibility);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [name, kiToolType, category, description, vendor, onChange]
    );

    const handleDescriptionChanged = useCallback(
        (description: string) => {
            setDescription(description);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [name, kiToolType, category, visibility, vendor, onChange]
    );

    const handleVendorChanged = useCallback(
        (vendor: string) => {
            setVendor(vendor);
            onChange({
                name,
                type: kiToolType,
                category,
                visibility,
                descriptions: [{locale: "en", text: description}],
                vendor
            });
        },
        [name, kiToolType, category, visibility, description, onChange]
    );
    const {t, es} = useLanguageContext();

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={6} md={8}>
                <TextInput label={t("tools.headers.name")} value={name} onChange={handleNameChanged} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextInput label={t("tools.headers.vendor")} value={vendor} onChange={handleVendorChanged} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <EnumSelectInput
                    label={t("tools.headers.toolType")}
                    options={Object.keys(KiToolType)}
                    lookupKey="tools.toolType"
                    value={kiToolType}
                    onChange={handleTypeChanged}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <EnumSelectInput
                    label={t("tools.headers.category")}
                    options={Object.keys(ToolCategory)}
                    lookupKey="tools.toolCategory"
                    value={category}
                    onChange={handleCategoryChanged}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <EnumSelectInput
                    label={t("tools.headers.visibility")}
                    options={Object.keys(Visibility)}
                    lookupKey="tools.toolVisibility"
                    value={visibility}
                    onChange={handleVisibilityChanged}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("tools.description")}
                    rows={10}
                    value={description}
                    onChange={handleDescriptionChanged}
                />
            </Grid>
        </Grid>
    );
}
