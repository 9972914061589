import {Button, Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useCallback} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";

export function AuthDebug() {
    const {t} = useLanguageContext();
    const {refresh} = useAuthContext();

    const [refreshingToken, setRefreshingToken] = React.useState<boolean>(false);

    const handleRefreshToken = useCallback(() => {
        setRefreshingToken(true);
        refresh().finally(() => setRefreshingToken(false));
    }, [refresh]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("authDebug.title")}
                        crumbs={[{label: t("authDebug.title"), href: "/auth/debug"}]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{t("authDebug.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" onClick={() => handleRefreshToken()} disabled={refreshingToken}>
                        {t("authDebug.refresh.title")}
                    </Button>
                </Grid>
                {refreshingToken && (
                    <Grid item xs={12}>
                        <Typography variant="body2">{t("authDebug.refresh.refreshing")}</Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}