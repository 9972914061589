import {Paper} from "@mui/material";
import {DataGrid, GridColDef, GridRowParams, GridRowSelectionModel} from "@mui/x-data-grid";
import * as React from "react";
import {useCallback} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {NoRowsOverlay} from "./NoRowsOverlay";

interface ATableProps<T> {
    rows: T[];
    columns: GridColDef[];
    onClick?: (row: T) => void;
    onSelectionChanged?: (selected: string[]) => void;
    noRowsLabel?: string;
    getRowId?: (row: T) => string;
}

export function ATable<T>(props: ATableProps<T>) {
    const {rows, columns, onClick, onSelectionChanged, noRowsLabel, getRowId} = props;
    const {t} = useLanguageContext();

    const handleOnClick = useCallback(
        (params: GridRowParams) => {
            if (onClick) {
                onClick(params.row as T);
            }
        },
        [onClick]
    );

    const handleSelectionChanged = useCallback(
        (model: GridRowSelectionModel) => {
            if (onSelectionChanged) {
                onSelectionChanged(model.map((row) => row.toString()));
            }
        },
        [onSelectionChanged]
    );

    return (
        <Paper>
            <DataGrid
                rows={rows}
                columns={columns}
                rowCount={rows.length}
                getRowId={(row) => getRowId ? getRowId(row) : row.uuid}
                pageSizeOptions={[10, 20, 50, 100]}
                checkboxSelection={Boolean(onSelectionChanged)}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(model) => handleSelectionChanged(model)}
                onRowClick={handleOnClick}
                slots={{
                    noRowsOverlay: () => <NoRowsOverlay message={noRowsLabel || t("table.noRowsFound")} />
                }}
                sx={{
                    "& .MuiDataGrid-cell:hover": {
                        cursor: onClick ? "pointer" : undefined
                    }
                }}
            />
        </Paper>
    );
}
