import {IAsset, IKiTool, Page, PageChange, ToolModification} from "./types";
import {Api} from "../libs/Api";

export class KiToolsProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<IKiTool>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/tools`, pageChange);
        return this.api.get<Page<IKiTool>>(url);
    }

    async search(tenantUuid: string, needle: string): Promise<IKiTool[]> {
        const url = `/api/v1/tenants/${tenantUuid}/tools/search?needle=${needle}`;
        return this.api.get<IKiTool[]>(url);
    }

    async create(tenantUuid: string, tool: ToolModification) {
        const url = `/api/v1/tenants/${tenantUuid}/tools`;
        return this.api.post<IKiTool>(url, tool);
    }

    async get(tenantUuid: string, toolUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/tools/${toolUuid}`;
        return this.api.get<IKiTool>(url);
    }

    async asset(tenantUuid: string, toolUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/tools/${toolUuid}/asset`;
        return this.api.get<IAsset>(url);
    }

    async modify(tenantUuid: string, toolUuid: string, tool: ToolModification) {
        const url = `/api/v1/tenants/${tenantUuid}/tools/${toolUuid}`;
        return this.api.put<IKiTool>(url, tool);
    }

    async delete(tenantUuid: string, toolUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/tools/${toolUuid}`;
        return this.api.delete(url);
    }

}