import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ConfirmDeleteButton} from "../../../base/buttons/ConfirmDeleteButton";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, MeasureModification, MultilingualTextWrapper} from "../../../domain/types";
import {gs} from "../../../theme";
import {MeasureForm} from "./MeasureForm";

interface MeasureEditProps {}

export function MeasureEdit(props: MeasureEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, language} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [measure, setMeasure] = useState<MeasureModification>();

    const [risks, setRisks] = useState<IAssetRisk[]>([]);

    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && routeParams.measureUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.measure(tenantUuid, routeParams.assetUuid, routeParams.measureUuid).then(setMeasure);
            assetsProvider.risks(tenantUuid, routeParams.assetUuid).then(setRisks);
        }
    }, [api, tenantUuid, routeParams]);

    const canSave = useMemo<boolean>(
        () => Boolean(measure && measure.titles.length > 0 && measure.descriptions.length > 0),
        [measure]
    );

    const handleSaveMeasure = useCallback(() => {
        if (asset && measure && routeParams.measureUuid && canSave && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .modifyMeasure(tenantUuid, asset.uuid, routeParams.measureUuid, measure)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, measure, api, tenantUuid, asset, navigate]);

    const handleDeleteMeasure = useCallback(() => {
        if (asset && routeParams.measureUuid && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .deleteMeasure(tenantUuid, asset.uuid, routeParams.measureUuid)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, asset, api, tenantUuid, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("measures.editMeasure")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`
                            },
                            {
                                label: measure ? new MultilingualTextWrapper(measure.titles).resolved(language) : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}`
                            },
                            {
                                label: t("measures.editMeasure"),
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}/edit`
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                {measure && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={submitting}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <MeasureForm measure={measure} onChange={setMeasure} risks={risks} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item>
                                            <Button
                                                onClick={handleSaveMeasure}
                                                color="primary"
                                                variant="contained"
                                                disabled={!canSave || submitting}
                                            >
                                                {t("save")}
                                            </Button>
                                        </Grid>

                                        <Grid item>
                                            <ConfirmDeleteButton
                                                onConfirm={handleDeleteMeasure}
                                                title={t("measures.deleteMeasure")}
                                                message={t("measures.deleteMeasureMessage")}
                                            >
                                                {t("delete")}
                                            </ConfirmDeleteButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
