export interface AuthConfiguration {
    hostedUiDomain: string;
    clientId: string;
}

export interface AuthCodeResponse {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
}

interface AuthenticatedTokenInternal {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    createdAt: Date;
    expiresAt: Date;
    refreshAfter: Date;
}

interface AuthenticatedTokenStored {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    createdAt: string;
    expiresAt: string;
    refreshAfter: string;
}

export class AuthenticatedToken {

    private token: AuthenticatedTokenInternal;

    constructor(token: AuthCodeResponse) {
        const now = new Date();
        this.token = {
            idToken: token.idToken,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
            expiresIn: token.expiresIn,
            tokenType: token.tokenType,
            createdAt: now,
            expiresAt: new Date(now.getTime() + (token.expiresIn * 1000)),
            refreshAfter: new Date(now.getTime() + (token.expiresIn * 750))
        };
    }

    get isLoggedIn() {
        return Boolean(this.token.accessToken && this.token.expiresAt > new Date());
    }

    get authHeader() {
        return `${this.token.tokenType} ${this.token.accessToken}`;
    }

    static nonAuthenticated(): AuthenticatedToken {
        return new AuthenticatedToken({
            idToken: "",
            accessToken: "",
            refreshToken: "",
            expiresIn: 0,
            tokenType: ""
        });
    }

    toSessionStorage() {
        const storable: AuthenticatedTokenStored = {
            idToken: this.token.idToken,
            accessToken: this.token.accessToken,
            refreshToken: this.token.refreshToken,
            expiresIn: this.token.expiresIn,
            tokenType: this.token.tokenType,
            createdAt: this.token.createdAt.toISOString(),
            expiresAt: this.token.expiresAt.toISOString(),
            refreshAfter: this.token.refreshAfter.toISOString()
        }
        sessionStorage.setItem("authenticatedToken", JSON.stringify(storable));
    }

    static fromSessionStorage(): AuthenticatedToken | undefined {
        const token = sessionStorage.getItem("authenticatedToken");
        if (token) {
            const constructed = this.nonAuthenticated();
            const readData: AuthenticatedTokenStored = JSON.parse(token);
            constructed.token = {
                ... readData,
                createdAt: new Date(readData.createdAt),
                expiresAt: new Date(readData.expiresAt),
                refreshAfter: new Date(readData.refreshAfter)
            };
            return constructed;
        }
    }

    clearSessionStorage() {
        sessionStorage.removeItem("authenticatedToken");
    }

    get refreshToken() {
        return this.token.refreshToken;
    }

    get expiresAt() {
        return this.token.expiresAt;
    }

    get refreshAfter() {
        return this.token.refreshAfter;
    }

    requiresRefresh() {
        return this.token.refreshAfter < new Date();
    }
}