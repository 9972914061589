import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, IMeasure, ITask, TaskModification} from "../../../domain/types";
import {gs} from "../../../theme";
import {TasksProvider} from "../../../domain/TasksProvider";
import {TaskForm} from "./TaskForm";
import {TaskWrapper} from "../../../domain/tasks";

interface AssetEditTaskProps {}

export function AssetEditTask(props: AssetEditTaskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, language} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [risks, setRisks] = useState<IAssetRisk[]>([]);
    const [measures, setMeasures] = useState<IMeasure[]>([]);
    const [task, setTask] = useState<ITask>();
    const [taskForm, setTaskForm] = useState<TaskModification>({
        titles: [],
        descriptions: []
    });
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risks(tenantUuid, routeParams.assetUuid).then(setRisks);
            assetsProvider.measures(tenantUuid, routeParams.assetUuid).then(setMeasures);
            const tasksProvider = new TasksProvider(api);
            tasksProvider.get(tenantUuid, routeParams.taskUuid).then((task) => {
                setTask(task);
                setTaskForm({
                    titles: task.titles,
                    descriptions: task.descriptions,
                    assetUuid: task.asset ? task.asset.uuid : undefined
                });
            });
        }
    }, [api, tenantUuid, routeParams]);

    const canUpdate = useMemo<boolean>(
        () => Boolean(task && task.titles.length > 0 && task.descriptions.length > 0),
        [task]
    );

    const handleSaveTask = useCallback(() => {
        if (task && asset && canUpdate && api.auth) {
            setSubmitting(true);
            const tasksProvider = new TasksProvider(api);
            tasksProvider
                .update(tenantUuid, task.uuid, taskForm)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/${task.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [canUpdate, task, taskForm, api, tenantUuid, asset, navigate]);

    const taskWrapper = useMemo<TaskWrapper | undefined>(() => task ? new TaskWrapper(task) : undefined, [task]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tasks.editTask")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: taskWrapper ? taskWrapper.title(language) : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks/${routeParams.taskUuid}`
                            },
                            {
                                label: t("tasks.editTask"),
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks/${routeParams.taskUuid}/edit`
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            {asset && (
                                <Grid item xs={12}>
                                    <TaskForm task={taskForm} onChange={setTaskForm} asset={asset} risks={risks} measures={measures} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleSaveTask}
                                    color="primary"
                                    variant="contained"
                                    disabled={!canUpdate || submitting}
                                >
                                    {t("save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
