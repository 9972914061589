import {IMeasure, MultilingualTextWrapper} from "./types";

export class MeasureWrapper {

    readonly measure: IMeasure;

    constructor(measure: IMeasure) {
        this.measure = measure;
    }

    title(language: string): string {
        const titleWrapper = new MultilingualTextWrapper(this.measure.titles);
        return titleWrapper.resolved(language);
    }
}