import {RadarChartData, RadarChartDataset} from "./charts";
import {RiskCategory} from "./types";


export interface AiDimensionValue {
    dimension: RiskCategory;
    value: number;
}

export interface AiPosture {
    id: string;
    label: string;
    preset?: boolean;
    values: AiDimensionValue[];
    color: number[];
}

export function fixedRange(id: string, label: string, color: number[], value: number): AiPosture {
    return {
        id: id,
        label: label,
        preset: true,
        color: color,
        values: Object.keys(RiskCategory).map((key) => ({
            dimension: key as RiskCategory,
            value: value
        }))
    };
}

export function randomAiPosture(label: string, color: number[]): AiPosture {
    const randomValue = () => Math.floor(Math.random() * 3) + 2;
    return {
        id: "random",
        label: label,
        color: color,
        values: Object.keys(RiskCategory).map((key) => ({
            dimension: key as RiskCategory,
            value: randomValue()
        }))
    }
}

export class AiPosturesWrapper {

    readonly postures: AiPosture[];
    readonly safeRange: AiPosture;
    readonly dangerRange: AiPosture;

    constructor(postures: AiPosture[], safeRange: AiPosture, dangerRange: AiPosture) {
        if(postures.length < 1) {
            throw new Error("At least one posture is required");
        }
        this.postures = postures;
        this.safeRange = safeRange;
        this.dangerRange = dangerRange;
    }

    fillEmptyDimensions(): void {
        const requiredDimensions = Object.keys(RiskCategory) as RiskCategory[];
        for(const posture of this.postures) {
            for(const dimension of requiredDimensions) {
                if(!posture.values.some((v) => v.dimension === dimension)) {
                    posture.values.push({dimension: dimension, value: 0});
                }
            }
        }
    }

    static toRadarChartDataset(posture: AiPosture): RadarChartDataset {
        if(posture.color.length != 3) {
            throw new Error(`RGB must have 3 values, but has ${posture.color.length}`);
        }
        const lineColor = `rgb(${posture.color[0]}, ${posture.color[1]}, ${posture.color[2]})`;
        const backgroundColor = `rgba(${posture.color[0]}, ${posture.color[1]}, ${posture.color[2]}, 0.15)`;
        return {
            label: posture.label,
            data: posture.values.map((v) => v.value),
            fill: !posture.preset ? "origin" : false,
            hidden: posture.preset,
            backgroundColor: backgroundColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: "#ffffff",
            pointHoverBackgroundColor: "#ffffff",
            pointHoverBorderColor: lineColor
        };
    }

    static exceedsRange(posture: AiPosture, comparedTo: AiPosture): boolean {
        for(const value of posture.values) {
            const comparedToValue = comparedTo.values.find((v) => v.dimension === value.dimension);
            if(!comparedToValue) {
                throw new Error(`Dimension ${value.dimension} is missing in comparedTo posture`);
            }
            if(value.value >= comparedToValue.value) {
                return true;
            }
        }
        return false;
    }

    static anyExceedsRange(postures: AiPosture[], comparedTo: AiPosture): boolean {
        return postures.some((p) => AiPosturesWrapper.exceedsRange(p, comparedTo));
    }

    toRadarChartData(es: any): RadarChartData {
        this.fillEmptyDimensions();
        const labels = this.postures[0].values
            .map((v) => v.dimension.toString())
            .map((value) => es("risks.riskCategories", value));
        const datasets = this.postures.map(AiPosturesWrapper.toRadarChartDataset);

        const safeRangeDataSet = AiPosturesWrapper.toRadarChartDataset(this.safeRange)
        safeRangeDataSet.hidden = !AiPosturesWrapper.anyExceedsRange(this.postures, this.safeRange);
        datasets.push(safeRangeDataSet);

        const dangerRangeDataSet = AiPosturesWrapper.toRadarChartDataset(this.dangerRange);
        dangerRangeDataSet.hidden = !AiPosturesWrapper.anyExceedsRange(this.postures, this.dangerRange);
        datasets.push(dangerRangeDataSet);

        return {
            labels: labels,
            datasets: datasets
        };
    }
}