import React from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IMeasure} from "../../../domain/types";
import {Grid} from "@mui/material";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {MeasureStateDisplay} from "../../../base/displays/MeasureStateDisplay";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {PriorityDisplay} from "../../../base/displays/PriorityDisplay";
import {LabeledData} from "../../../base/data/LabeledData";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";

interface MeasureViewProps {
    measure: IMeasure;
}

export function MeasureView(props: MeasureViewProps) {
    const {measure} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("measures.formLabels.Title")}>
                <MultiLingualTextDisplay texts={measure.titles} />
            </LabeledItem>
            <LabeledItem label={t("measures.formLabels.State")}>
                <MeasureStateDisplay state={measure.state} />
            </LabeledItem>
            <AGridItemGrow>
                <LabeledData label={t("measures.formLabels.Priority")}>
                    <PriorityDisplay priority={measure.priority} />
                </LabeledData>
            </AGridItemGrow>
            {measure.risk &&
                <>
                    <LabeledItem label={t("measures.formLabels.Probability")}>
                        <ProbabilityDisplay probability={measure.risk.probability} />
                    </LabeledItem>
                    <LabeledItem label={t("measures.formLabels.Severity")}>
                        <SeverityDisplay severity={measure.risk.severity} />
                    </LabeledItem>
                </>
            }
            <Grid item xs={12}>
                <LabeledData label={t("measures.formLabels.Description")}>
                    <MultiLingualTextDisplay texts={measure.descriptions} />
                </LabeledData>
            </Grid>
        </Grid>
    );
}