import {ITask, MultilingualTextWrapper} from "./types";

export class TaskWrapper {

    readonly task: ITask;

    constructor(task: ITask) {
        this.task = task;
    }

    title(language: string): string {
        const titleWrapper = new MultilingualTextWrapper(this.task.titles);
        return titleWrapper.resolved(language);
    }

}