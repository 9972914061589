import * as React from "react";
import Box from "@mui/material/Box";
import theme, {gs} from "../theme";
import {Grid, Typography} from "@mui/material";
import {AGridItemGrow} from "../base/layout/AGridItemGrow";

interface BottomNavProps {
}

export function BottomNav(props: BottomNavProps) {

    return (
        <Box p={2} sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main
        }}>
            <Grid container spacing={gs}>
                <AGridItemGrow>
                    <Typography variant="body2">© 2024 - Artiligence</Typography>
                </AGridItemGrow>
            </Grid>
        </Box>
    );
}
