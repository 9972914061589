import {Divider, Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {TextInput} from "../../../base/inputs/TextInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IAsset, IAssetRisk, IMeasure, TaskModification} from "../../../domain/types";
import {gs} from "../../../theme";

interface TaskFormProps {
    task: TaskModification;
    onChange: (value: TaskModification) => void;
    asset?: IAsset;
    risks: IAssetRisk[];
    measures: IMeasure[];
}

export function TaskForm(props: TaskFormProps) {
    const {task, asset, risks, measures, onChange} = props;

    const {t} = useLanguageContext();

    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        if (task.titles.length > 0) {
            setTitle(task.titles[0].text);
        }
        if (task.descriptions.length > 0) {
            setDescription(task.descriptions[0].text);
        }
    }, [task]);

    const handleTitleChanged = useCallback(
        (title: string) => {
            setTitle(title);
            onChange({
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                assetUuid: asset ? asset.uuid : undefined,
            });
        },
        [title, description, onChange]
    );

    const handleDescriptionChanged = useCallback(
        (description: string) => {
            setDescription(description);
            onChange({
                titles: [{locale: "en", text: title}],
                descriptions: [{locale: "en", text: description}],
                assetUuid: asset ? asset.uuid : undefined,
            });
        },
        [title, description, onChange]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        TODO allow selecting risks and measures
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
                <TextInput label={t("tasks.title")} value={title} onChange={handleTitleChanged} />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("tasks.description")}
                    rows={10}
                    value={description}
                    onChange={handleDescriptionChanged}
                />
            </Grid>
        </Grid>
    );
}
