import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {useLanguageContext} from "../../context/language/LanguageContext";
import React, {useCallback, useMemo, useState} from "react";
import {Button, Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {emptyToolModification, ToolModification} from "../../domain/types";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {ToolForm} from "./edit/ToolForm";
import {KiToolsProvider} from "../../domain/KiToolsProvider";

interface ToolCreateProps {}

export function ToolCreate(props: ToolCreateProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tool, setTool] = useState<ToolModification>(emptyToolModification);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const canSave = useMemo<boolean>(
        () =>
            Boolean(
                tool &&
                tool.name &&
                tool.type &&
                tool.category &&
                tool.visibility &&
                tool.descriptions.length > 0 &&
                tool.vendor
            ),
        [tool]
    );

    const handleCreateTool = useCallback(() => {
        if (tool && canSave && api.auth) {
            setSubmitting(true);
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider
                .create(tenantUuid, tool)
                .then((createdTool) => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/tools/${createdTool.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [canSave, tool, api, tenantUuid, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs title={t("tools.create")} crumbs={[
                        {
                            label: t("tools.plural"),
                            href: `/tenants/${tenantUuid}/tools`
                        },
                        {
                            label:t("tools.create"),
                            href: `/tenants/${tenantUuid}/tools/add`
                        },
                    ]} />
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <ToolForm tool={tool} onChange={setTool} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item>
                                            <Button
                                                onClick={handleCreateTool}
                                                color="primary"
                                                variant="contained"
                                                disabled={!canSave || submitting}
                                            >
                                                {t("tools.save")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );

}