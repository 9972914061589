export interface Page<T> {
    content: T[];
    pageable: {
        pageNumber: number;
        pageSize: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
    last: boolean;
    totalPages: number;
    totalElements: number;
    first: boolean;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    numberOfElements: number;
    empty: boolean;
}

export interface PageChange {
    page: number;
    pageSize: number;
}

export const defaultPage: PageChange = {
    page: 0,
    pageSize: 25
};

export interface MetaData {
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}

export interface MultilingualText {
    locale: string;
    text: string;
}

export class MultilingualTextWrapper {
    readonly texts: MultilingualText[];

    constructor(texts: MultilingualText[]) {
        this.texts = texts;
    }

    resolved(language: string): string {
        let resolved = this.texts.filter((text) => text.locale === language).pop();
        if (resolved) {
            return resolved.text;
        }
        resolved = this.texts.filter((text) => text.locale === "en").pop();
        if (resolved) {
            return resolved.text;
        }
        if (this.texts.length > 0) {
            return this.texts[0].text;
        }
        return `Cannot resolve text in locale ${language} or default locale "en"`;
    }
}

export interface Address {
    line1?: string;
    line2?: string;
    zip?: string;
    city?: string;
    countryCode?: string;
}

export interface UserInfo {
    sub: string;
    emailVerified: boolean;
    givenName: string;
    name: string;
    email: string;
}

export interface TenantUserInfo {
    id: {
        tenantUuid: string;
        username: string;
    };
    metaData: MetaData;
    role: Role;
}

export interface TenantUserInfo {
    user: UserInfo;
    tenantUser: TenantUserInfo;
}

export interface IIndustry {
    code: string;
    version: string;
    names: MultilingualText[];
}

export interface ITenant {
    uuid: string;
    version: number;
    name: string;
    address?: Address;
    industry: IIndustry;
    metaData: MetaData;
}

export interface Permission {
    entity: string;
    permissions: string[];
}

export interface Permissions {
    permissions: Permission[];
}

export interface RoleCreation {
    name: string;
    description: string;
    permissions: Permissions;
}

export interface RoleModification extends RoleCreation {}

export interface Role extends RoleCreation {
    uuid: string;
    version: number;
    metaData: MetaData;
}

export enum KiToolType {
    Library = "Library",
    Tool = "Tool",
    Platform = "Platform"
}

export enum ToolCategory {
    TextGeneration = "TextGeneration",
    Business = "Business",
    Coding = "Coding",
    ImageGeneration = "ImageGeneration",
    DesignGeneration = "DesignGeneration",
    Entertainment = "Entertainment",
    Education = "Education",
    Misc = "Misc",
    SEO = "SEO",
    AvatarGeneration = "AvatarGeneration",
    Fashion = "Fashion",
    AudioGeneration = "AudioGeneration",
    Health = "Health",
    News = "News",
    Science = "Science",
    Finance = "Finance",
    Prompts = "Prompts"
}

export enum Visibility {
    CreatingUser = "CreatingUser",
    CreatingTenant = "CreatingTenant",
    All = "All"
}

export enum RiskCategory {
    AiAct = "AiAct",
    Privacy = "Privacy",
    InformationSecurity = "InformationSecurity",
    TradeSecrets = "TradeSecrets",
    Copyright = "Copyright",
    EmploymentLaw = "EmploymentLaw",
    Ethics = "Ethics",
    Discrimination = "Discrimination",
    ProductLiability = "ProductLiability"
}

export enum MeasureState {
    Identified = "Identified",
    InProgress = "InProgress",
    Completed = "Completed"
}

export enum TaskState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed"
}

export interface ToolModification {
    name: string;
    type: KiToolType;
    category: ToolCategory;
    visibility: Visibility;
    descriptions: MultilingualText[];
    vendor: string;
    basedOnUuid?: string;
}

export const emptyToolModification: ToolModification = {
    name: "",
    type: KiToolType.Tool,
    category: ToolCategory.Misc,
    visibility: Visibility.CreatingTenant,
    descriptions: [],
    vendor: ""
}

export interface IKiTool extends Omit<ToolModification, "basedOnUuid"> {
    uuid: string;
    version: number;
    basedOn?: IKiTool;
    owner: ITenant;
    metaData: MetaData;
}

export interface AssetModification {
    name: string;
    type: KiToolType;
    category: ToolCategory;
    visibility: Visibility;
    descriptions: MultilingualText[];
    vendor: string;
}

export interface IAsset extends AssetModification {
    uuid: string;
    version: number;
    basedOn: IKiTool;
    owner: ITenant;
    metaData: MetaData;
}

export interface AssetRiskModification {
    name: string;
    category: RiskCategory;
    descriptions: MultilingualText[];
    risk: {
        probability: number;
        severity: number;
    };
}

export interface IAssetRisk extends AssetRiskModification {
    uuid: string;
    version: number;
    asset: IAsset;
    metaData: MetaData;
}

export interface MeasureModification {
    priority: number;
    state: MeasureState;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    risk: {
        probability: number;
        severity: number;
    };
    assetRisk?: IAssetRisk;
}

export interface IMeasure extends MeasureModification {
    uuid: string;
    version: number;
    owner: ITenant;
    asset: IAsset;
    metaData: MetaData;
}

export interface ITask {
    uuid: string;
    version: number;
    priority: number;
    state: TaskState;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    owner: ITenant;
    asset: IAsset;
    assetRisk?: IAssetRisk;
    measure?: IMeasure;
    metaData: MetaData;
}

export interface TaskModification {
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    assetUuid?: string;
    assetRiskUuid?: string;
    measureUuid?: string;
}

export interface Questionnaire {
    slug: string;
    version: number;
    type?: KiToolType;
    category?: ToolCategory;
    visibility: Visibility;
    owner: ITenant;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    metaData: MetaData;
}

export enum AnswerType {
    Info = "Info",
    Text = "Text",
    FormattedText = "FormattedText",
    MultilineText = "MultilineText",
    TextList = "TextList",
    Integer = "Integer",
    Float = "Float",
    Boolean = "Boolean",
    YesNoUndefined = "YesNoUndefined",
    Checkbox = "Checkbox",
    SingleFileUpload = "SingleFileUpload",
    MultipleFileUpload = "MultipleFileUpload",
    HumanReadableProbabilities = "HumanReadableProbabilities",
    MultipleChoiceSingleAnswer = "MultipleChoiceSingleAnswer",
    MultipleChoiceMultipleAnswers = "MultipleChoiceMultipleAnswers",
    Unit = "Unit",
    Date = "Date",
    DateTime = "DateTime"
}

export enum AnswerScoreModifier {
    Equals = "Equals",
    NotEquals = "NotEquals",
    TextMatches = "TextMatches",
    TextContains = "TextContains",
    Greater = "Greater",
    GreaterOrEquals = "GreaterOrEquals",
    Less = "Less",
    LessOrEquals = "LessOrEquals",
    Multiply = "Multiply",
    Filled = "Filled"
}

export interface QuestionOption {
    slug: string;
    locale: string;
    label: string;
}

export interface QuestionShowSubQuestions {
    always: boolean;
    answer?: string;
    modifier: AnswerScoreModifier;
}

export interface Question {
    uuid: string;
    version: number;
    parent?: Question;
    children?: Question[];
    position: number;
    type: AnswerType;
    required: boolean;
    showSubQuestions: QuestionShowSubQuestions;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    options?: QuestionOption[];
    metaData: MetaData;
}

export interface QuestionEditRequest {
    type: AnswerType;
    required: boolean;
    titles: MultilingualText[];
    options?: QuestionOption[];
    descriptions: MultilingualText[];
}

export interface Answer {
    questionUuid: string;
    answer: string;
}

export interface SearchRequest {
    language: string;
    needle: string;
}

export interface SearchResponseElement {
    entity: string;
    path: string;
    display: string;
}

export interface SearchResponse {
    results: SearchResponseElement[];
}

export class SearchElementWrapper {
    readonly element: SearchResponseElement;

    constructor(element: SearchResponseElement) {
        this.element = element;
    }

    route(tenantUuid: string): string {
        return `/tenants/${tenantUuid}${this.element.path}`;
    }
}
