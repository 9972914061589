import React from "react";
import {ITask, TaskState} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Grid} from "@mui/material";
import {gs} from "../../theme";
import {TaskStateDisplay} from "../../base/displays/TaskStateDisplay";
import {LabeledItem} from "../../base/data/LabeledItem";
import {PriorityDisplay} from "../../base/displays/PriorityDisplay";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {LabeledData} from "../../base/data/LabeledData";
import {TaskStateQuickChangeButton} from "../../base/inputs/TaskStateQuickChangeButton";

interface TaskViewProps {
    task: ITask;
    onStateChange?: (nextState: TaskState) => Promise<void>;
}

export function TaskView(props: TaskViewProps) {
    const {task, onStateChange} = props;
    const {t} = useLanguageContext();

    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("tasks.state")}>
                <Grid container spacing={1}>
                    <Grid item>
                        <TaskStateDisplay state={task.state} />
                    </Grid>
                    {onStateChange &&
                        <Grid item>
                            <TaskStateQuickChangeButton taskState={task.state} onChange={onStateChange} />
                        </Grid>
                    }
                </Grid>
            </LabeledItem>
            <LabeledItem label={t("tasks.priority")}>
                <PriorityDisplay priority={task.priority} />
            </LabeledItem>
            <LabeledItem label={t("tasks.title")}>
                <MultiLingualTextDisplay texts={task.titles} />
            </LabeledItem>
            <Grid item xs={12}>
                <LabeledData label={t("tasks.description")}>
                    <MultiLingualTextDisplay texts={task.descriptions} />
                </LabeledData>
            </Grid>
        </Grid>
    );
}